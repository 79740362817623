import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
import { useAuthStore } from '@/js/store/auth/AuthStore';
import { useWeb3Store } from '@/js/store/web3/Web3Store';
import { questsRoutes } from '@/js/constants/questsRoutes';
import { authRoute } from '@/js/auth/constants/auth';
import { dashboardRoutesPaths } from '@/js/quests/dashboard/constants/dashboardRoutesPaths';
import { DASHBOARD_PATH } from '@/js/constants/generalConstants';
import { useDashboardStore } from '@/js/store/quests/dashboard/DashboardStore';
import { useGuildStore } from '@/js/store/quests/dashboard/GuildStore';
import { useGuildsStudioStore } from '@/js/store/quests/dashboard/GuildsStudioStore';

async function fetchDashboardData(dashboardStore) {
    dashboardStore.isMissionsLoading = true;

    try {
        await Promise.all([
            dashboardStore.fetchMissionsData(),
        ]);
    } finally {
        dashboardStore.isMissionsLoading = false;
    }
}

export function logoutSuccessHandler() {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const web3Store = useWeb3Store();
    const dashboardStore = useDashboardStore();
    const guildStore = useGuildStore();
    const guildsStudioStore = useGuildsStudioStore();

    const path = window.location.pathname;

    authStore.$reset();
    authStore.isUserFetchCompleted = true;
    userStore.$reset();
    web3Store.$reset();
    dashboardStore.$reset();
    guildStore.$reset();
    guildsStudioStore.$reset();

    if (path === dashboardRoutesPaths.MISSIONS_ROUTE_PATH) {
        fetchDashboardData(dashboardStore);
    }

    if (path === dashboardRoutesPaths.SETTINGS_ROUTE_PATH
        || path === dashboardRoutesPaths.WALLET_ROUTE_PATH
        || path === dashboardRoutesPaths.GUILDS_STUDIO_ROUTE_PATH) {
        window.location.href = questsRoutes.MISSIONS_ROUTE;
    } else if (path.startsWith(DASHBOARD_PATH)) {
        window.location.href = authRoute.CONNECT;
    }
}
