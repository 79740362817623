import { defineStore } from 'pinia';
import { GuildType } from '@/js/quests/dashboard/pages/guilds/types/guilds';
import { getGuilds } from '@/js/quests/dashboard/pages/guilds/api/guilds';
import { DEFAULT_SORT } from '@/js/quests/dashboard/pages/guilds/constants/filters';
import { GuildTypeOptionsEnum } from '@/js/quests/dashboard/pages/guilds/types/filters';
import { useAuthStore } from '@/js/store/auth/AuthStore';
import {
    deleteStudioGuild,
    getGuildsStudioStats,
    getUnlockStudioStepsStatus,
    setUnlockStudioRole,
} from '@/js/quests/dashboard/pages/guilds-studio/api/studioApi';
import { StudioStatsData, UnlockStudioStepsStatus } from '@/js/quests/dashboard/pages/guilds-studio/types/studio';

export interface GuildsStoreState {
    ownedGuilds: GuildType[] | [];
    isLoadingOwnedGuilds: boolean;
    isFetchOwnedGuildsCompleted: boolean;
    isOwnedGuildsInfinityLoading: boolean;
    isOwnedGuildsLastPage: boolean;
    ownedGuildsCurrentPage: number;
    isAllGuildsLastPage: boolean;
    filters: {
        search: string;
        sort: string;
    };
    guildsStudioStats: StudioStatsData;
    isLoadingGuildsStudioStats: boolean;
    unlockStudioStepsStatus: UnlockStudioStepsStatus[];
    isLoadingUnlockStudioStepsStatus: boolean;
    isUnlockedRequestInProgress: boolean;
    isUnlockedRequestSuccess: boolean;
    isGuildDeleteLoading: boolean;
}

export const useGuildsStudioStore = defineStore({
    id: 'guildsStudioStore',
    state: (): GuildsStoreState => ({
        ownedGuilds: [],
        isLoadingOwnedGuilds: false,
        isFetchOwnedGuildsCompleted: false,
        isOwnedGuildsInfinityLoading: false,
        isOwnedGuildsLastPage: false,
        ownedGuildsCurrentPage: 1,
        isAllGuildsLastPage: false,
        filters: {
            search: '',
            sort: DEFAULT_SORT,
        },
        guildsStudioStats: {
            follower_count: 0,
            player_count: 0,
            mission_count: 0,
        },
        isLoadingGuildsStudioStats: false,
        unlockStudioStepsStatus: [],
        isLoadingUnlockStudioStepsStatus: false,
        isUnlockedRequestInProgress: false,
        isUnlockedRequestSuccess: false,
        isGuildDeleteLoading: false,
    }),
    actions: {
        resetOwnedGuildsCurrentPage() {
            this.ownedGuildsCurrentPage = 1;
        },
        increaseOwnedGuildsCurrentPage() {
            this.ownedGuildsCurrentPage += 1;
        },
        async fetchOwnedGuilds(page: number, perPage: number) {
            try {
                this.isLoadingOwnedGuilds = true;

                const authStore = useAuthStore();
                const guildType = authStore.isAdmin() ? GuildTypeOptionsEnum.ALL : GuildTypeOptionsEnum.OWNED;

                const newOwnedGuildsData = await getGuilds(
                    { search: this.filters.search, sort: this.filters.sort, guildType },
                    { page, perPage },
                );

                if (page === 1) {
                    this.resetOwnedGuildsCurrentPage();
                    this.ownedGuilds = newOwnedGuildsData.data;
                } else if (newOwnedGuildsData?.data?.length) {
                    this.ownedGuilds = [
                        ...this.ownedGuilds,
                        ...newOwnedGuildsData.data,
                    ];
                }

                this.isOwnedGuildsLastPage = page >= newOwnedGuildsData.meta.last_page;
            } finally {
                this.isLoadingOwnedGuilds = false;
                this.isFetchOwnedGuildsCompleted = true;
                this.isOwnedGuildsInfinityLoading = false;
            }
        },
        async fetchGuildsStudioStats() {
            this.isLoadingGuildsStudioStats = true;

            try {
                const response = await getGuildsStudioStats();
                this.guildsStudioStats = response.data;
            } finally {
                this.isLoadingGuildsStudioStats = false;
            }
        },
        async fetchUnlockStudioStepsStatus() {
            this.isLoadingUnlockStudioStepsStatus = true;

            try {
                const response = await getUnlockStudioStepsStatus();
                this.unlockStudioStepsStatus = response.data;
            } finally {
                this.isLoadingUnlockStudioStepsStatus = false;
            }
        },
        async unlockStudioRole() {
            try {
                this.isUnlockedRequestInProgress = true;
                await setUnlockStudioRole();
                this.isUnlockedRequestSuccess = true;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            } finally {
                this.isUnlockedRequestInProgress = false;
            }
        },
        async deleteGuild(guildSlug: string) {
            this.isGuildDeleteLoading = true;

            try {
                await deleteStudioGuild(guildSlug);

                this.ownedGuilds = this.ownedGuilds.filter((guild) => guild.slug !== guildSlug);
            } finally {
                this.isGuildDeleteLoading = false;
            }
        },
    },
});
